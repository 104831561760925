<h1>Generate Report</h1>
<div class="row">
    <div class="form-fields" [formGroup]="exportDetailsForm">
        <h2 class="summary-heading">Summary</h2>
        
        <h3>Range:</h3>
        <div class="summary-data">
            <img style="margin-right: 5px;" src="assets/icons/date.svg"> 
            <span>{{data.startDate | date: 'MM/dd/yyyy'}}</span> 
            <img src="assets/icons/arrow-right-short.svg"> 
            <img style="margin-right: 5px;" src="assets/icons/date.svg"> 
            <span>{{data.endDate | date: 'MM/dd/yyyy'}}</span>
        </div>
        
        <h3>Students:</h3>
        <div class="summary-data">
            <p>{{ getStudentNames() }}</p>
        </div>
        
        <div class="buttons">
            <button class="button button-light-blue" [disabled]="this.reportBeingGenerated" (click)="onGenerate()">Generate</button>
        </div>
        <div class="info-div" *ngIf="this.reportBeingGenerated">
            <p class="info-text">Request to generate reports sent. Please check your email shortly.</p>
        </div>
    </div>
    <div>
        <img width="400px" src="assets/icons/generate-report-image.svg">
    </div>
</div>