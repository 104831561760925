

  <div *ngIf="assessmentsList" class="flex-column main">
    <div class="heading">
        <div class="title">Title</div>
        <div class="date">Date</div>
        <div class="category">Category</div>
        <div class="weight">Weight</div>
        <div class="average">Class Avg.</div>
        <div class="filter">
          <button (click)="this.toggleFilterDropdown()">
            <img height="25px" src="../../../../../assets/icons/sort.svg">
          </button>
          <div *ngIf="this.showFilterDropdown" class="filter-dropdown">
            <button (click)="filterAssessmentsBy('date')">Date</button>
            <button (click)="filterAssessmentsBy('category')">Category</button>
          </div>
        </div>
    </div>
    <div class="line"></div>
    <div class="flex-row row"
        *ngFor="let item of assessmentsList; let i=index"
        (click)="toggleAssessment(i)"
        [class.active]="activeAssessment === i">
            <div class="title">{{ item.title }}</div>
            <div class="date">{{ item.date }}</div>
            <div class="category">{{ item.category_name }}</div>
            <div class="weight">{{ item.weight }}</div>
            <div class="average">{{ item.class_average }}</div>
            <!-- <button style="position: absolute; right: 0px">Click me!</button> -->
            <i class="material-icons edit" mat-button [matMenuTriggerFor]="menu">edit</i>
            <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openEditDialog(i)">Edit Assessment</button>
            <button mat-menu-item *ngIf="studentMarks && !studentMarks[0]?.input" (click)="editMarks(i)">Edit Marks</button>
            <button mat-menu-item *ngIf="!studentMarks || studentMarks[0]?.input" (click)="inputMarks(i)">Input Marks</button>
            </mat-menu>
            <i class="material-icons material-symbols-outlined delete" (click)="onDeleteAssessment(i)">delete</i>
    </div>
    <div class="no-data center" *ngIf="assessmentsList.length === 0 && !loadingAssessments">There are no assessments for this section...</div>
    <mat-spinner class="center" *ngIf="loadingAssessments" diameter="40"></mat-spinner>
  </div>

<ng-template #noData class="flex-column main">
    
</ng-template>
  <button class="button-orange" (click)="openCreateDialog()">Create+</button>
