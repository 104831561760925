import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { AssessmentDialogComponent } from '../assessment-dialog/assessment-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MarksDialogComponent } from '../marks-dialog/marks-dialog.component';
import { Subject, filter, takeUntil } from 'rxjs';
import { Course } from 'src/models/courses';
import { DataStoreService } from 'src/app/services/data-store.service';
import { RestApiService } from 'src/app/services/rest-api.service';

@Component({
  selector: 'app-assessment-list',
  templateUrl: './assessment-list.component.html',
  styleUrls: ['./assessment-list.component.scss']
})
export class AssessmentListComponent implements OnInit {

  @Output() setActiveAssessment = new EventEmitter();
  @Output() refreshData = new EventEmitter();
  @Input() assessmentsList: any;
  @Input() studentMarks: any;
  @Input() activeAssessment!: number;
  @Input() loadingAssessments = false;
  activeCourse: Course;

  form!: FormArray;
  destroy$ = new Subject();
  showFilterDropdown = false;
  sortedDesc: boolean = false;

  constructor(public dialog: MatDialog, private ds: DataStoreService, private restApi: RestApiService) { }

  ngOnInit(): void {
    this.ds.activeCourse$.pipe(
      filter(course => !!course),
      takeUntil(this.destroy$)
    ).subscribe((course: Course) => {
      this.activeCourse = course;
    })
  }

  toggleAssessment(index: number) {
    this.setActiveAssessment.emit(index);
  }

  openEditDialog(index: number) {
    const dialogRef = this.dialog.open(AssessmentDialogComponent, {
      width: '650px',
      height: '450px',
      panelClass: 'main-dialog',
      data: { sectionId: this.activeCourse.id, editing: true, assessment: this.assessmentsList[index]},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.refresh) {
        this.refreshData.emit();
        this.setActiveAssessment.emit(this.activeAssessment);
      }
    });
  }

  openCreateDialog() {
      const dialogRef = this.dialog.open(AssessmentDialogComponent, {
        width: '650px',
        height: '450px',
        panelClass: 'main-dialog',
        data: { sectionId: this.activeCourse.id, editing: false },
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.refresh) {
          this.refreshData.emit();
        }
      });
  }

  inputMarks(index: number) {
    const studentMarks = this.studentMarks.map(student => ({ ...student, received_mark: ''}));

    const dialogRef = this.dialog.open(MarksDialogComponent, {
      width: '650px',
      height: '600px',
      panelClass: 'main-dialog',
      data: { assessment: this.assessmentsList[index], studentMarks: studentMarks },
    });

    dialogRef.afterClosed().subscribe(result => {
      setTimeout(() => {
        this.refreshData.emit();
        this.setActiveAssessment.emit(this.activeAssessment);
      }, 500)
    });
  }

  editMarks(index: number) {
    const dialogRef = this.dialog.open(MarksDialogComponent, {
      width: '650px',
      height: '600px',
      panelClass: 'main-dialog',
      data: { assessment: this.assessmentsList[index], editing: true, studentMarks: this.studentMarks },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.refresh) {
        setTimeout(() => {
          this.refreshData.emit();
          this.setActiveAssessment.emit(this.activeAssessment);
        }, 500)
      }
    });
  }

  onDeleteAssessment(index: number) {
    const dialogRef = this.dialog.open(AssessmentDialogComponent, {
      width: '650px',
      height: '150px',
      panelClass: 'main-dialog',
      data: { delete: true, sectionId: this.activeCourse.id, editing: false, assessment: this.assessmentsList[index] },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.refresh) {
        this.refreshData.emit();
        this.setActiveAssessment.emit(null);
      }
    });
  }

  toggleFilterDropdown() {
    this.showFilterDropdown = !this.showFilterDropdown;
  }

  filterAssessmentsBy(filterOption: string) {
    switch (filterOption) {
      // Filter Assessments by Date values in Descending order
      case 'date': 
        if (this.sortedDesc) {
          this.assessmentsList.sort(function(first: any, last: any) { 
              return Date.parse(first.date) - Date.parse(last.date); 
          })
          this.sortedDesc = false;
        } else {
          this.assessmentsList.sort(function(first: any, last: any) { 
            return Date.parse(last.date) - Date.parse(first.date); 
          })
          this.sortedDesc = true;
        }
        break;
      // Filter Assessments by Category Name values in Alphabetical order, pushing the null values to the end of the list
      case 'category':
        this.assessmentsList.sort(function(first : any, last : any) { 
          if (first.category_name == null) {
            return 1;
          }
        
          if (last.category_name == null) {
            return -1;
          }
        
          if (first.category_name == last.category_name) {
            return 0;
          }
        
          return first.category_name > last.category_name ? 1 : -1;
        })
        break;
    }
    this.toggleFilterDropdown();
  }

}
