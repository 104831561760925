import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { RestApiService } from 'src/app/services/rest-api.service';
import { AttendanceStatus, StudentAttendance } from 'src/models/attendance';
import { Student } from 'src/models/student';
import { DataStoreService } from "../../../services/data-store.service";
import { Subject, filter, takeUntil } from 'rxjs';
import { Course } from "../../../../models/courses";
import { ExpandableCardComponent } from '../../shared/expandable-cards/expandable-card/expandable-card.component';
import * as dayjs from 'dayjs';

@Component({
  selector: 'attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss']
})
export class AttendanceComponent implements OnInit {
  activeCourseName: string;
  activeCourse;
  attendance: any;
  todaysRecords: any;
  attendanceLoading = false;
  submitInProgress = false;

  active = false;
  lockedMessage = ''
  locked = false;

  statusSubmitted = false;

  startDate;
  endDate;
  today;
  dates: any = [];

  containerHeight = 0;
  @ViewChild('calendar') calendar: ElementRef;

  destroy$ = new Subject();

  constructor(private restApi: RestApiService, private ds: DataStoreService) {
  }

  ngOnInit(): void {
    this.ds.activeCourse$.pipe(
      filter(course => !!course),
      takeUntil(this.destroy$)
      ).subscribe((course) => {
        this.activeCourse = course;
        this.activeCourseName = course.course_title
        
        this.generateDays();
    })
  }

  toggleCard() {}

  getContainerHeight() {
    // this is to set a max height for the dividing line - may have it removed
    this.containerHeight = (this.calendar.nativeElement as HTMLDivElement).clientHeight;
  }

  getAttendance() {
    this.attendanceLoading = true;
    this.statusSubmitted = false;
    this.restApi.getAttendance(this.activeCourse.id, this.startDate, this.endDate).subscribe((res: AttendanceStatus[]) => {
      this.attendanceLoading = false;
      const names = res.map(records => records.firstName + ' ' + records.surname);
      const students = [...new Set(names)]; // make array of students (unique)

      const attendanceRecords = students.map(student => {
        let records = res.filter(records => records.firstName + ' ' + records.surname === student);
        const statuses = records.map(record => record.status);
        // const statuses = records.map(record => 'A');

        return { student, records: statuses, student_id: records[0].student_id };
      })

      this.attendance = attendanceRecords;
      this.todaysRecords = attendanceRecords.map(record => {
        const todaysIndex = new Date().getDay() - 1 + 5;
        let status = record.records[todaysIndex];
        if (status !== 'NULL') {
          this.statusSubmitted = true;
        } else {
          status = null;
        }

        return { name: record.student, status, student_id: record.student_id }
      });

      this.setStudentsNames();
      setTimeout(() => {
        this.getContainerHeight();
      })
    })
  }

  setStatus(status: string, index: number) {
    this.todaysRecords[index].status = status === this.todaysRecords[index].status ? 'P' : status;
  }

  generateDays(startDate?: dayjs.Dayjs, endDate?: dayjs.Dayjs) {
    this.dates = [];

    // if startDate and endDate are not passed in, then we want to set the startDate and endDate
    // based on today's date
    if (!startDate) {
      let today = dayjs();
      // if it is either Saturday or Sunday, we want to set today to Friday
      if (today.day() === 6) {
        today = today.date(today.date() - 1);
      } else if (today.day() === 0) {
        today = today.date(today.date() - 2);
      }

      const lastWeekMonday = today.date(today.date() - 7).day(1);
      startDate = lastWeekMonday
      endDate = today;
      this.today = today.format('YYYY-MM-DD');
    }

    this.startDate = startDate.format('YYYY-MM-DD');
    this.endDate = endDate.format('YYYY-MM-DD');

    const addDayToCalendar = (day: any) => {
      const date: { day: string, date: string, today?: boolean } = { day: day.format('ddd'), date: day.date() };
      if (day.format('YYYY-MM-DD') === this.today) {
        date.today = true;
      }
      this.dates.push(date)
    }

    let day = startDate;

    while (day.date() !== endDate.date()) {
      if (day.day() !== 0 && day.day() !== 6) {
        addDayToCalendar(day)
      }
      day = day.date(day.date() + 1);
    }

    addDayToCalendar(endDate);

    // after setting days, we do a call to get attendance
    this.getAttendance();
  }

  toggleDates(direction: string) {
    const deltaDays = 14;
    const currentStartDate = dayjs(this.startDate);
    let startDate: dayjs.Dayjs;
    let endDate: dayjs.Dayjs;

    if (direction === 'forward' && this.today !== this.endDate) {
      startDate = currentStartDate.date(currentStartDate.date() + deltaDays);
      endDate = startDate.date(startDate.date() + deltaDays - 3);
      endDate = endDate.isAfter(this.today) ? dayjs(this.today): endDate; // endDate can't be past today's date
    } else if (direction === 'back') {
      startDate = currentStartDate.date(currentStartDate.date() - 14);
      endDate = startDate.date(startDate.date() + deltaDays - 3);
    }

    this.generateDays(startDate, endDate);
  }

  setStudentsNames() {
      if (this.todaysRecords) {
        this.todaysRecords.map(student => {
          while (student.name.length > 20) {
            // iterate through student names, abbreviating last name to single letter
            // until the full student name's length is under 20 characters
            const names = student.name.split(' ');
            let lastName = names[names.length - 1];
            if (lastName.length === 2 && lastName[1] === '.') {
              names.pop();
              lastName = names[names.length - 1];
            }
            names.pop();
            student.name = names.join(' ') + ' ' + lastName[0] + '.';
          }
        })
      }
  }

  updateAttendance() {
    this.attendanceLoading = true;
    this.submitInProgress = true;
    const req = this.todaysRecords.map(student => {
      student.status = !student.status ? 'P' : student.status;
      return {
        student_id: student.student_id,
        status: student.status,
        section_id: this.activeCourse.id,
        date: dayjs().format('YYYY-MM-DD')
      }
    });

    if (!this.statusSubmitted) {
      this.restApi.createAttendance(req).subscribe(res => {
        this.statusSubmitted = true;
        this.submitInProgress = false;
        this.getAttendance();
      });
    } else {
      this.restApi.updateAttendance(req).subscribe(res => {
        this.submitInProgress = false;
        this.getAttendance();
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }
}
