<div *ngIf="!data.delete; else delete">
    <h1>{{ editing ? 'Edit Assessment' : 'New Assessment'}}</h1>

    <div class="form-fields flex-row" [formGroup]="assessmentDetailsForm">
        <div class="field title">
            <label>Title</label>
            <input formControlName="title" type="text">
        </div>

        <div class="field date">
            <label>Date</label>
            <input formControlName="date" type="text" placeholder="YYYY-MM-DD">
        </div>

        <div class="field weight">
            <label>Weight</label>
            <input formControlName="weight" type="text">
        </div>

        <div class="field total">
            <label>Total Mark</label>
            <input formControlName="total_mark" type="text">
        </div>

        <div class="field category">
            <label>Category (optional)</label>
            <input formControlName="category_name" type="text">
        </div>
    </div>

    <div *ngIf="editing" class="buttons">
        <button class="button button-orange" (click)="onEditAssessment()">Save</button>
        <button class="button button-orange" (click)="closeDialog()">Cancel</button>
    </div>

    <div *ngIf="!editing" class="buttons">
        <button class="button button-orange" (click)="onCreateAssessment()">Create+</button>
        <!-- <button class="button button-orange" (click)="onCreateAndInput()">Create & Input+</button> -->
    </div>
</div>

<ng-template #delete>
    <div class="delete">
        <h1>Are you sure you want to delete this assessment?</h1>

        <div class="buttons">
            <button class="button button-orange" (click)="onDeleteAssessment()">Yes</button>
            <button class="button button-orange" (click)="closeDialog()">No</button>
        </div>
    </div>
</ng-template>