
<div class="content">
  <div class="assessment-list">
    <app-assessment-list
      [assessmentsList]="assessmentsList"
      [studentMarks]="studentMarks"
      (setActiveAssessment)="showStudentMarks($event)"
      [activeAssessment]="activeAssessment"
      (refreshData)="getData()"
      [loadingAssessments]="loadingAssessments">
    </app-assessment-list>
  </div>
  <div class="student-marks">
    <app-student-marks
      [studentMarks]="studentMarks"
      [loadingMarks]="loadingMarks">
    </app-student-marks>
  </div>
</div>