<h1>{{ data.editing ? 'Edit Marks' : 'Input Marks'}}</h1>

    <div class="form-fields flex-column scroll" [formGroup]="studentMarksForm">
        <div *ngFor="let student of data.studentMarks; let i = index">
            <label [for]="'student' + student.student_id">
                {{generateFullName(student)}}
            </label>

            <input [id]="'student' + student.student_id" type="text" [formControlName]="student.student_id" (keyup.arrowup)="goToPreviousMark(i)" (keyup.arrowdown)="goToNextMark(i)" (keyup.enter)="submitMarks()">

            <span>/ {{ student.total_mark }}</span>
        </div>
    </div>

<div class="buttons">
    <button class="button button-orange" (click)="submitMarks()">Save</button>
    <button class="button button-orange" (click)="closeDialog()">Cancel</button>
</div>